    import React, { useState, useEffect } from "react";
    import { useParams, useNavigate } from "react-router-dom";
    import { supabase } from "../supabaseClient";

    const EditMosqueDetails = () => {
    const { mosqueId } = useParams();
    const navigate = useNavigate();
    const [mosqueDetails, setMosqueDetails] = useState({
        name: "",
        address: "",
        location_url: "",
    });
    const [parkingDetails, setParkingDetails] = useState({
        onsite_parking: "",
        disable_bays: "",
        off_street_parking: "",
        road_name: "",
        address: "",
        distance_to_mosque: "",
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        const fetchDetails = async () => {
        if (!mosqueId) {
            setError("Invalid mosque ID");
            return;
        }

        try {
            const { data: mosqueData, error: mosqueError } = await supabase
            .from("mosques")
            .select("name, address, location_url")
            .eq("id", mosqueId)
            .single();

            if (mosqueError) throw mosqueError;
            setMosqueDetails(mosqueData);

            const { data: parkingData, error: parkingError } = await supabase
            .from("parking")
            .select(
                "onsite_parking, disable_bays, off_street_parking, road_name, address, distance_to_mosque"
            )
            .eq("mosque_id", mosqueId);

            if (parkingError) throw parkingError;
            if (parkingData.length === 1) {
            setParkingDetails(parkingData[0]);
            } else if (parkingData.length > 1) {
            setError("Multiple parking records found for this mosque.");
            }
        } catch (err) {
            setError(err.message);
        }
        };

        fetchDetails();
    }, [mosqueId]);
    

    const handleInputChange = (e, setState) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
        const { error: mosqueError } = await supabase
            .from("mosques")
            .update(mosqueDetails)
            .eq("id", mosqueId);

        if (mosqueError) throw mosqueError;

        const { data: existingParkingData, error: parkingFetchError } = await supabase
            .from("parking")
            .select("id")
            .eq("mosque_id", mosqueId)
            .single();

        if (existingParkingData) {
            const { error: parkingUpdateError } = await supabase
            .from("parking")
            .update(parkingDetails)
            .eq("mosque_id", mosqueId);

            if (parkingUpdateError) throw parkingUpdateError;
        } else {
            const { error: parkingInsertError } = await supabase
            .from("parking")
            .insert({ ...parkingDetails, mosque_id: mosqueId });

            if (parkingInsertError) throw parkingInsertError;
        }

        navigate(`/mosque/${mosqueId}`);
        } catch (err) {
        setError(err.message);
        } finally {
        setLoading(false);
        }
    };

    return (
        <div style={{ maxWidth: "600px", margin: "auto", padding: "20px" }}>
        <h1>Edit Mosque Details </h1>
        {error && (
            <div style={{ color: "red", marginBottom: "20px", fontWeight: "bold" }}>
            {error}
            </div>
        )}
        <form onSubmit={handleSubmit}>
            <fieldset style={{ marginBottom: "20px" }}>
            <legend><h2>Mosque Information</h2></legend>
            <label>
                Name:
                <input
                type="text"
                name="name"
                value={mosqueDetails.name}
                onChange={(e) => handleInputChange(e, setMosqueDetails)}
                required
                style={{ display: "block", width: "100%", marginBottom: "10px" }}
                />
            </label>
            <label>
                Address:
                <input
                type="text"
                name="address"
                value={mosqueDetails.address}
                onChange={(e) => handleInputChange(e, setMosqueDetails)}
                required
                style={{ display: "block", width: "100%", marginBottom: "10px" }}
                />
            </label>
            <label>
                Location URL:
                <input
                type="text"
                name="location_url"
                value={mosqueDetails.location_url}
                onChange={(e) => handleInputChange(e, setMosqueDetails)}
                style={{ display: "block", width: "100%", marginBottom: "10px" }}
                />
            </label>
            </fieldset>

            <fieldset style={{ marginBottom: "20px" }}>
            <legend><h2>Parking Information</h2></legend>
            <label>
                Onsite Parking:
                <input
                type="text"
                name="onsite_parking"
                value={parkingDetails.onsite_parking}
                onChange={(e) => handleInputChange(e, setParkingDetails)}
                style={{ display: "block", width: "100%", marginBottom: "10px" }}
                />
            </label>
            <label>
                Disabled Bays:
                <input
                type="text"
                name="disable_bays"
                value={parkingDetails.disable_bays}
                onChange={(e) => handleInputChange(e, setParkingDetails)}
                style={{ display: "block", width: "100%", marginBottom: "10px" }}
                />
            </label>
            <label>
                Off Street Parking:
                <input
                type="text"
                name="off_street_parking"
                value={parkingDetails.off_street_parking}
                onChange={(e) => handleInputChange(e, setParkingDetails)}
                style={{ display: "block", width: "100%", marginBottom: "10px" }}
                />
            </label>
            <label>
                Road Name:
                <input
                type="text"
                name="road_name"
                value={parkingDetails.road_name}
                onChange={(e) => handleInputChange(e, setParkingDetails)}
                style={{ display: "block", width: "100%", marginBottom: "10px" }}
                />
            </label>
            <label>
                Address:
                <input
                type="text"
                name="address"
                value={parkingDetails.address}
                onChange={(e) => handleInputChange(e, setParkingDetails)}
                style={{ display: "block", width: "100%", marginBottom: "10px" }}
                />
            </label>
            <label>
                Distance to Mosque (minutes):
                <input
                type="text"
                name="distance_to_mosque"
                value={parkingDetails.distance_to_mosque}
                onChange={(e) => handleInputChange(e, setParkingDetails)}
                style={{ display: "block", width: "100%", marginBottom: "10px" }}
                />
            </label>
            </fieldset>

            <button
            type="submit"
            disabled={loading}
            style={{
                padding: "10px 20px",
                backgroundColor: "#f16876",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                fontSize: "16px",
            }}
            >
            {loading ? "Saving..." : "Save Changes"}
            </button>
        </form>
        </div>
    );
    };

    export default EditMosqueDetails;
