import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../supabaseClient";
import "./AdminMosqueDetails.css";

const AdminMosqueDetails = () => {
  const [mosques, setMosques] = useState([]);
  const [selectedMosqueId, setSelectedMosqueId] = useState(null);
  const [prayerTimes, setPrayerTimes] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const formatDate = (date) => new Date(date).toLocaleDateString();
  const getDayName = (date) =>
    new Date(date).toLocaleDateString("en-US", { weekday: "long" });
  const formatTime = (time) => (time ? time.slice(0, 5) : "N/A");

  const handleLogout = async () => {
    await supabase.auth.signOut();
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/admin-login";
  };

  const fetchMosques = async () => {
    try {
      const { data, error } = await supabase.from("mosques").select("id, name");
      if (error) throw error;
      setMosques(data);
    } catch (err) {
      setError(err.message);
    }
  };

  const fetchPrayerTimes = async () => {
    if (!selectedMosqueId) return;
    try {
      const { data, error } = await supabase
        .from("prayertimes")
        .select("*")
        .eq("mosque_id", selectedMosqueId);

      if (error) throw error;

      const filtered = data
        .filter((prayer) => {
          const prayerDate = new Date(prayer.date);
          return (
            prayerDate.getMonth() + 1 === parseInt(selectedMonth) &&
            prayerDate.getFullYear() === parseInt(selectedYear)
          );
        })
        .sort((a, b) => new Date(a.date) - new Date(b.date));

      setPrayerTimes(filtered);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleInputChange = (e, id, field) => {
    const value = e.target.value;
    setPrayerTimes((prev) =>
      prev.map((prayer) =>
        prayer.id === id ? { ...prayer, [field]: value } : prayer
      )
    );
  };

  const updatePrayerTimes = async () => {
    try {
      for (const prayer of prayerTimes) {
        const { error } = await supabase
          .from("prayertimes")
          .update({
            fajar_start: prayer.fajar_start,
            fajar_jamaat: prayer.fajar_jamaat,
            zuhr_start: prayer.zuhr_start,
            zuhr_jamaat: prayer.zuhr_jamaat,
            asr_start: prayer.asr_start,
            asr_jamaat: prayer.asr_jamaat,
            maghrib: prayer.maghrib,
            isha_start: prayer.isha_start,
            isha_jamaat: prayer.isha_jamaat,
          })
          .eq("id", prayer.id);

        if (error) throw error;
      }
      alert("Prayer times updated successfully.");
      fetchPrayerTimes();
    } catch (err) {
      alert("Error updating prayer times: " + err.message);
    }
  };

  useEffect(() => {
    fetchMosques();
  }, []);

  useEffect(() => {
    fetchPrayerTimes();
  }, [selectedMosqueId, selectedMonth, selectedYear]);

  return (
    <div className="admin-mosque-details">
      {error && <p className="error-message">Error: {error}</p>}
      <div className="header">
        <buttons onClick={handleLogout} className="logout-button">
        📴
        </buttons>
        <h1>Manage Mosques</h1>
      </div>
      <div className="filters">
        <label>
          Select Mosque:
          <select
            value={selectedMosqueId || ""}
            onChange={(e) => setSelectedMosqueId(e.target.value)}
          >
            <option value="" disabled>
              Select a mosque
            </option>
            {mosques.map((mosque) => (
              <option key={mosque.id} value={mosque.id}>
                {mosque.name}
              </option>
            ))}
          </select>
        </label>

        <label>
          Select Month:
          <select
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
          >
            {[...Array(12).keys()].map((m) => (
              <option key={m + 1} value={m + 1}>
                {new Date(0, m).toLocaleString("default", { month: "long" })}
              </option>
            ))}
          </select>
        </label>

        <label>
          Select Year:
          <select
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
          >
            {Array.from(
              { length: 5 },
              (_, i) => new Date().getFullYear() - 2 + i
            ).map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </label>
      </div>

      <button
        onClick={() => navigate(`/upload-csv/${selectedMosqueId}`)}
        disabled={!selectedMosqueId}
        className="upload-csv-button"
      >
        Upload CSV
      </button>
      <button
  onClick={() => navigate(`/edit-mosque/${selectedMosqueId}`)}
  disabled={!selectedMosqueId}
>
  Edit Details
</button>


      <div className="prayer-times-table">
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Fajr Start</th>
              <th>Fajr Jamaat</th>
              <th>Zuhr Start</th>
              <th>Zuhr Jamaat</th>
              <th>Asr Start</th>
              <th>Asr Jamaat</th>
              <th>Maghrib</th>
              <th>Isha Start</th>
              <th>Isha Jamaat</th>
            </tr>
          </thead>
          <tbody>
            {prayerTimes.map((prayer) => (
              <tr key={prayer.id}>
                <td>{formatDate(prayer.date)}</td>
                <td>
                  <input
                    type="time"
                    value={formatTime(prayer.fajar_start)}
                    onChange={(e) =>
                      handleInputChange(e, prayer.id, "fajar_start")
                    }
                  />
                </td>
                <td>
                  <input
                    type="time"
                    value={formatTime(prayer.fajar_jamaat)}
                    onChange={(e) =>
                      handleInputChange(e, prayer.id, "fajar_jamaat")
                    }
                  />
                </td>
                <td>
                  <input
                    type="time"
                    value={formatTime(prayer.zuhr_start)}
                    onChange={(e) =>
                      handleInputChange(e, prayer.id, "zuhr_start")
                    }
                  />
                </td>
                <td>
                  <input
                    type="time"
                    value={formatTime(prayer.zuhr_jamaat)}
                    onChange={(e) =>
                      handleInputChange(e, prayer.id, "zuhr_jamaat")
                    }
                  />
                </td>
                <td>
                  <input
                    type="time"
                    value={formatTime(prayer.asr_start)}
                    onChange={(e) =>
                      handleInputChange(e, prayer.id, "asr_start")
                    }
                  />
                </td>
                <td>
                  <input
                    type="time"
                    value={formatTime(prayer.asr_jamaat)}
                    onChange={(e) =>
                      handleInputChange(e, prayer.id, "asr_jamaat")
                    }
                  />
                </td>
                <td>
                  <input
                    type="time"
                    value={formatTime(prayer.maghrib)}
                    onChange={(e) => handleInputChange(e, prayer.id, "maghrib")}
                  />
                </td>
                <td>
                  <input
                    type="time"
                    value={formatTime(prayer.isha_start)}
                    onChange={(e) =>
                      handleInputChange(e, prayer.id, "isha_start")
                    }
                  />
                </td>
                <td>
                  <input
                    type="time"
                    value={formatTime(prayer.isha_jamaat)}
                    onChange={(e) =>
                      handleInputChange(e, prayer.id, "isha_jamaat")
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <button onClick={updatePrayerTimes} disabled={!selectedMosqueId}>Update Database</button>
      </div>
    </div>
  );
};

export default AdminMosqueDetails;
