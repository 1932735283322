import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Papa from "papaparse";
import { supabase } from "../supabaseClient";
import "./UploadCSVPage.css";

const UploadCSVPage = () => {
  const { mosqueId } = useParams();
  const [mosqueName, setMosqueName] = useState("");
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMosqueName = async () => {
      try {
        const { data, error } = await supabase
          .from("mosques")
          .select("name")
          .eq("id", mosqueId)
          .single();

        if (error) {
          console.error("Error fetching mosque name:", error.message);
        } else {
          setMosqueName(data.name);
        }
      } catch (err) {
        console.error("Unexpected error:", err.message);
      }
    };

    fetchMosqueName();
  }, [mosqueId]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === "text/csv") {
      setFile(selectedFile);
    } else {
      setError("Please select a valid CSV file.");
    }
  };

  const handleFileUpload = () => {
    if (!file) {
      setError("No file selected.");
      return;
    }

    setUploading(true);
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (result) => {
        const parsedData = result.data.filter((row) => row.date); // Ignore rows without a date

        try {
          for (const row of parsedData) {
            const {
              date,
              fajar_start = null,
              fajar_jamaat = null,
              zuhr_start = null,
              zuhr_jamaat = null,
              asr_start = null,
              asr_jamaat = null,
              maghrib = null,
              isha_start = null,
              isha_jamaat = null,
            } = row;

            const cleanRow = {
              mosque_id: mosqueId,
              date: date ? new Date(date).toISOString() : null, // Convert to ISO format
              fajar_start: fajar_start || null,
              fajar_jamaat: fajar_jamaat || null,
              zuhr_start: zuhr_start || null,
              zuhr_jamaat: zuhr_jamaat || null,
              asr_start: asr_start || null,
              asr_jamaat: asr_jamaat || null,
              maghrib: maghrib || null,
              isha_start: isha_start || null,
              isha_jamaat: isha_jamaat || null,
            };

            if (!cleanRow.date) continue;

            const { error } = await supabase.from("prayertimes").insert(cleanRow);

            if (error) throw error;
          }

          alert("CSV uploaded successfully!");
          navigate(`/admin`);
        } catch (err) {
          setError("Error uploading data: " + err.message);
        } finally {
          setUploading(false);
        }
      },
      error: (err) => {
        setError("Error parsing the file: " + err.message);
        setUploading(false);
      },
    });
  };

  return (
    <div className="upload-csv-page">
      <h1>Upload CSV for Prayer Times - {mosqueName}</h1>
      {error && <p className="error-message">{error}</p>}
      <div className="upload-section">
        <input type="file" accept=".csv" onChange={handleFileChange} />
        <button onClick={handleFileUpload} disabled={uploading}>
          {uploading ? "Uploading..." : "Upload CSV"}
        </button>
        <a
          href="/prayer_times_format.csv"
          download
          className="download-link"
        >
          Download Format File
        </a>
      </div>
      <button onClick={() => navigate(`/admin`)}>Back to Mosque Details</button>
    </div>
  );
};

export default UploadCSVPage;
