// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import MosquesList from "./components/MosquesList";
import MosqueDetails from "./components/MosqueDetails";
import ParkingInfo from "./components/ParkingInfo";
import AdminMosqueDetails from "./components/AdminMosqueDetails";
import AdminLogin from "./components/AdminLogin";
import UploadCSVPage from "./components/UploadCSVPage"; // Import the new component
import EditMosqueDetails from "./components/EditMosqueDetails";

// Protected Route Component
const ProtectedRoute = ({ element, redirectTo = "/admin-login" }) => {
  const isAuthenticated = localStorage.getItem("adminToken"); // Check for admin token
  return isAuthenticated ? element : <Navigate to={redirectTo} />;
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MosquesList />} />
        <Route path="/mosque/:id" element={<MosqueDetails />} />
        <Route path="/parking/:id" element={<ParkingInfo />} />
        <Route path="/admin-login" element={<AdminLogin />} />
        {/* Protected admin routes */}
        <Route
          path="/admin"
          element={
            <ProtectedRoute
              element={<AdminMosqueDetails />}
              redirectTo="/admin-login"
            />
          }
        />
        <Route
  path="/edit-mosque/:mosqueId"
  element={
    <ProtectedRoute
      element={<EditMosqueDetails />}
      redirectTo="/admin-login"
    />
  }
/>

        <Route
          path="/upload-csv/:mosqueId"
          element={
            <ProtectedRoute
              element={<UploadCSVPage />}
              redirectTo="/admin-login"
            />
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
